<template>
  <main-layout>
    <!-- Page Heading -->
    <div class="d-sm-flex align-items-center justify-content-between mb-4">
      <h1 class="h3 mb-0 text-gray-800">BRANDS</h1>
      <router-link
        to="/addbrand"
        class="d-none d-sm-inline-block btn btn-sm btn-success shadow-sm"
        ><i class="fas fa-plus fa-sm text-white-50"></i>Add Brand</router-link
      >
    </div>
    <div class="bg-white border rounded">
      <table class="table">
        <thead class="thead-dark">
          <tr>
            <th scope="col">Brand Name</th>
            <th scope="col">Actions</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="brand in brands" :key="brand._id">
            <td>{{ brand.name }}</td>
            <td>
              <router-link
                :to="`/editbrand/${brand._id}`"
                class="btn btn-warning mr-2"
                >Edit</router-link
              ><button
                @click.prevent="deleteBrand(brand._id)"
                class="btn btn-danger"
              >
                Delete
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </main-layout>
</template>

<script>
import axios from "axios";
import MainLayout from "@/components/layout/MainLayout";
export default {
  name: "Brands",
  data() {
    return {
      brands: [],
    };
  },
  methods: {
    async getBrands() {
      try {
        const res = await axios.get("/api/v1/brands");
        if (res.status === 200) {
          this.brands = res.data.brands;
        }
      } catch (e) {
        //   ---------------- HANDLE ERROR
        console.log(e);
      }
    },
    async deleteBrand(id) {
      try {
        if (confirm("Are you sure?")) {
          const res = await axios.delete(`/api/v1/brands/${id}`, {
            headers: {
              authorization: `bearer ${this.$store.state.token}`,
            },
          });
          this.getBrands();
        }
      } catch (e) {
        // ------ HANDLE ERROR
        console.log(e);
      }
    },
  },
  mounted() {
    this.getBrands();
  },
  components: {
    MainLayout,
  },
};
</script>

<style>
</style>